import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useContext } from 'react';
import { AuthContext } from './Components/AuthContext';

import { NavBar } from './Components/NavBar';
import { Footer } from './Components/Footer'

const LazyBanner = React.lazy(() => import('./Components/Banner'));
const LazyNDA = React.lazy(() => import('./Components/NDA'));
const LazyNotFound = React.lazy(() => import('./Components/NotFound'));
const LazyCharacterDesign = React.lazy(() => import('./Components/CharacterDesign'));
const LazyIllustration = React.lazy(() => import('./Components/Illustration'));
const LazySketchbook = React.lazy(() => import('./Components/Sketchbook'));
const LazyPropDesign = React.lazy(() => import('./Components/PropDesign'));
const LazyLoginPage = React.lazy(() => import('./Components/Login'));
const LazyContact = React.lazy(() => import('./Components/Contact'));

function App() {

  const { currentUser } = useContext(AuthContext)

  const RequireAuth = ({ children }) => {
    return currentUser ? (children) : <Navigate to="/login" />
  }

  const RequireAdminAuth = ({ children }) => {
    return currentUser ? (children) : <Navigate to="/Zja6*d-gf5-HJvb56*W44a-sd+5UqBwg.AE675A65A.YgAQ.654yBw" />
  }

  return (
    <Router>
      <div className="App">
        <NavBar />
        <div className="content">
          <Routes>
            <Route exact path='/' element={<React.Suspense fallback='Loading...'><LazyBanner /></React.Suspense>} />
            <Route exact path='/Work' element={<React.Suspense fallback='Loading...'><LazyBanner /></React.Suspense>} />
            <Route exact path='/login' element={<React.Suspense fallback='Loading...'><LazyLoginPage /></React.Suspense>} />
            <Route exact path='/About&Contact' element={<React.Suspense fallback='Loading...'><LazyContact /></React.Suspense>} />
            <Route exact path='/Illustration' element={<React.Suspense fallback='Loading...'><LazyIllustration /></React.Suspense>} />
            <Route exact path='/CharacterDesign' element={<React.Suspense fallback='Loading...'><LazyCharacterDesign /></React.Suspense>} />
            <Route exact path='/Sketchbook' element={<React.Suspense fallback='Loading...'><LazySketchbook /></React.Suspense>} />
            <Route exact path='/PropDesign' element={<React.Suspense fallback='Loading...'><LazyPropDesign /></React.Suspense>} />
            <Route exact path='/NDA' element={<RequireAuth> <React.Suspense fallback='Loading...'><LazyNDA /></React.Suspense> </RequireAuth>} />
            <Route exact path='/*' element={<React.Suspense fallback='Loading...'><LazyNotFound /></React.Suspense>} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;

/*

import { AdminLogin } from './Components/AdminLogin';
import { UploadImage } from './Components/UploadImage';
import { Section } from './Components/Section';
import { CreateSection } from './Components/CreateSection';
import { TestBanner } from './Components/TestBanner';

            <Route exact path='/Section' element={<Section /> } />
            <Route exact path='/CreateSection' element={<CreateSection /> } />
            <Route exact path='/TestBanner' element={<TestBanner />} />
            <Route exact path='/Zja6*d-gf5-HJvb56*W44a-sd+5UqBwg.AE675A65A.YgAQ.654yBw' element={<AdminLogin />} />
            <Route exact path='/VolcanBarva' element={<RequireAdminAuth> <UploadImage /> </RequireAdminAuth>} />


*/