import { Link } from 'react-router-dom';
import IconLinkedIn from '../Assets/Img/Icons/Icon-LinkedIn.svg'
import IconBlueSky from '../Assets/Img/Icons/Icon-BlueSky.svg'
import IconInstagram from '../Assets/Img/Icons/Icon-Instagram.svg'

export const Footer = () => {
    return (
        <section className="footer">
            <div>
                <footer >
                    <hr className="solid" />
                    <div className="social-icon">
                        <a href='https://www.linkedin.com/in/alexander-chacon/'><img src={IconLinkedIn} alt='' /></a>
                        <a href='https://bsky.app/profile/avantaron.bsky.social'><img src={IconBlueSky} alt='' /></a>
                        <a href='https://www.instagram.com/avantaron/'><img src={IconInstagram} alt='' /></a>
                    </div>
                    <div className="footerRights">All rights reserved. Alexander Chacón 2024 ®</div>
                </footer >
            </div>
        </section>
    )

}