import { Nav, Navbar, Container, Row } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { motion } from "framer-motion"
import { Link } from "react-router-dom";

const logo = 'https://firebasestorage.googleapis.com/v0/b/portafolioalexchacon.appspot.com/o/Sections%2Fheathers%2Flogo.webp?alt=media&token=8f433539-db41-4a6c-9491-30bb4ede391f'

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <section>
      <section className='Nav-bar-general'>
        <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
          <Container className='navbar-header'>

            <Navbar.Brand href="/">
              <motion.div
                whileHover={{
                  scale: 1.03,
                }}
                onHoverStart={e => { }}
                onHoverEnd={e => { }}  >
                <LazyLoadImage src={logo} className='logo' alt="Logo" />
              </motion.div>
            </Navbar.Brand>

            <motion.div
              whileHover={{
                scale: 1.03,
              }}
              onHoverStart={e => { }}
              onHoverEnd={e => { }}  >
              <Nav.Link href="/" className={activeLink === 'Work' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}><h1>ALEXANDER CHACÓN</h1></Nav.Link>
            </motion.div>

          </Container>
          <Container className='navbar-links'>
            <Nav>

              <Nav.Link href="Work" className={activeLink === 'Work' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>
                <motion.div
                  whileHover={{
                    scale: 1.09,
                  }}
                  onHoverStart={e => { }}
                  onHoverEnd={e => { }}  >
                  WORK
                </motion.div>
              </Nav.Link>

              <Nav.Link href="NDA" className={activeLink === 'login' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>
                <motion.div
                  whileHover={{
                    scale: 1.09,
                  }}
                  onHoverStart={e => { }}
                  onHoverEnd={e => { }}  >
                  NDA
                </motion.div>
              </Nav.Link>

              <Nav.Link href="About&Contact" className={activeLink === 'About&Contact' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>
                <motion.div
                  whileHover={{
                    scale: 1.09,
                  }}
                  onHoverStart={e => { }}
                  onHoverEnd={e => { }}  >
                  ABOUT & CONTACT
                </motion.div>
              </Nav.Link>

            </Nav>
          </Container>
        </Navbar>
      </section>
      <hr className="solid" />
    </section>

  );
}

